import React from 'react'
import PropTypes from 'prop-types'
import './layout.css'
import Footer from '../components/footer'
import styled from 'styled-components'
import SubPageHeader from '../components/subPageHeader'
import SubPageHero from '../components/subPageHero'
import ScrollTop from '../components/scrollTop'
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const CollectionPageLayout = ({ children, title, description1, description2 }) => {
  return (
    <Layout>
      <SubPageHeader />
      <SubPageHero title={title} description1={description1} description2={description2} />
      <main>{children}</main>
      <ScrollTop />
      <br></br>
      <Footer />
    </Layout>
  )
}

CollectionPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CollectionPageLayout

import React from 'react'
import SEO from '../components/seo'
import CollectionPageLayout from '../layouts/collectionPageLayout'
import { Section, SectionTitle } from '../components/styled/section'
import { Column1, Column2, GridWithGap } from '../components/styled/grid'
import { MapContainer } from '../components/map/mapContainer'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { generateWorkshopPagePrefix } from '../url-helpers'

const hasLatitude = (workshop) => workshop.lat

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #cccccc;
`

const ListItem = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`

const WorkshopLink = styled(Link)`
  display: block;
  padding: 1rem;
  width: 100%;
  color: inherit;
  text-decoration: none;
`

const WorkshopPageTemplate = ({ pageContext: { page, workshops } }) => {
  const seoTitle = `Verksted i ${page.name}`

  const markers = workshops.filter(hasLatitude)

  return (
    <CollectionPageLayout page={page}>
      <SEO title={seoTitle} />
      <Section>
        <SectionTitle>Våre verksted {page.name}</SectionTitle>

        {workshops && workshops.length > 0 ? (
          <GridWithGap>
            <Column1>
              <List>
                {workshops.map((workshop, index) => {
                  const prefix = generateWorkshopPagePrefix(workshop)

                  return (
                    <ListItem key={index}>
                      <WorkshopLink to={`/${prefix}/${workshop.slug}`}>
                        <strong>{workshop.name}</strong>
                        <br />
                        <small>
                          {workshop.street} {workshop.zip} {workshop.city}
                          {workshop.phone && (
                            <>
                              <br />
                              <span>{workshop.phone}</span>
                            </>
                          )}
                        </small>
                      </WorkshopLink>
                    </ListItem>
                  )
                })}
              </List>
            </Column1>
            <Column2 style={{ minHeight: '50vh' }}>
              <MapContainer workshops={markers} zoom={4} />
            </Column2>
          </GridWithGap>
        ) : (
          <div style={{ minHeight: '60vh' }}>
            <Link to="/steder" className="link">
              Vis alle steder
            </Link>
            <div style={{ marginTop: '2rem' }}>
              Vi fant dessverre ingen verksteder i {page.name}.
            </div>
          </div>
        )}
      </Section>
    </CollectionPageLayout>
  )
}

export default WorkshopPageTemplate
